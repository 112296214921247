import React from "react";
import Table from "../layout/table.jsx";
import { DateRangePicker } from "react-dates";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import Rodal from "rodal";
import Select from "react-select";
import TimePicker from "rc-time-picker";
import { toast } from "react-toastify";
import { Prompt } from "react-router";
import { isNumber, set } from "lodash";
import API from "../../services/api.jsx";
import helpers from "../../services/helpers.jsx";

const localizer = momentLocalizer(moment);

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

let formats = {
  dayFormat: (date, culture, localizer) =>
    // Change default heading day format from 'Tue 13/11' to 'Tuesday'
    localizer.format(date, "dddd"),
  eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
    return (
      localizer.format(start, "HH:mm") + " - " + localizer.format(end, "HH:mm")
    );
  },
};

function secondsToTime(timestamp) {
  let hours = Math.floor(timestamp / 60 / 60);
  let minutes = Math.floor(timestamp / 60) - hours * 60;
  let seconds = timestamp % 60;
  return {
    hours,
    minutes,
    seconds,
  };
}

class CampaignForm extends React.Component {
  constructor(props) {
    super(props);

    // Convert dates
    if (props.entity.startDate)
      props.entity.startDate = moment(props.entity.startDate);

    if (props.entity.endDate)
      props.entity.endDate = moment(props.entity.endDate);

    // Set state
    this.state = {
      entity: props.entity,
      newEvent: {
        activeDays: {},
      },
      newVoiceOver: {
        activeDays: {},
      },
      renameManualPlaylist: {
        name: "",
        playlist: "",
      },
      renameLivePlaylist: {
        hidden: false,
        name: "",
        playlist: "",
      },
      selectedOption: null,
      popup: {
        start: moment(),
        end: moment(),
      },
      playlistIndex: props.playlists.map((playlist) => playlist.id),
      collectionIndex: props.collections.map((collection) => collection.id),
      formHasChanged: false,
      showPopupManualPlaylist: false,
      showPopup: false,
      showPopupRenameHidden: false,
      showPopupRename: false,
      showPopupVoiceOver: false,
      calendarEvents: [],
      displaySchedule: true,
      displayVoiceOver: true,
    };

    // Set callback on device change
    this.props.devices.onSelect = () => {
      this.setState({ formHasChanged: true });
    };

    // Prepare schedules
    let schedules = this.prepareSchedules(props.entity.schedules);
    this.state.schedules = schedules;
    this.state.schedulesIndex = schedules.map((schedule) => schedule.id);

    // Set manual playlists table configuration
    // we do it here since we need `this` to be in the current scope when
    // the user clicks on the "add manual playlist" button
    if (this.props.voiceOvers) {
      this.props.voiceOvers.actions[0].onClick = (e) => {
        e.preventDefault();
        this.newVoiceOver();
      };
      this.props.voiceOvers.onRowClick = (e) => {
        this.handleEditVoiceOver(e);
      };
    }

    this.props.manualPlaylists.actions[0].onClick = (e) => {
      e.preventDefault();
      this.setState({
        showPopupManualPlaylist: true,
      });
    };

    this.props.manualPlaylists.onRowClick = (e) => {
      this.openManualPlaylistName(e);
      this.setState({
        showPopupRename: true,
      });
    };

    this.props.livePlaylists.onRowClick = (e) => {
      this.openLivePlaylistName(e);
      this.setState({
        showPopupRenameHidden: true,
      });
    };

    const calendarEvents = [
      ...this.buildCalendarEventList(schedules),
      ...this.buildCalendarVoiceOverList(props.voiceOvers?.entities),
    ];
    this.state.calendarEvents = calendarEvents;
  }

  handleEditVoiceOver(voiceOver) {
    const editVoiceOver = {
      id: voiceOver.id,
      name: voiceOver.name,
      hash: voiceOver.hash,
      file: voiceOver.file,
      campaign: voiceOver.campaign,
      recurring: voiceOver.recurring ?? false,
      activeDays: voiceOver.activeDays,
      bitRate: voiceOver.bitRate,
      sampleRate: voiceOver.sampleRate,
      duration: voiceOver.duration,
    };
    if (voiceOver.startTime) {
      if (moment.isDate(voiceOver.startTime)) {
        editVoiceOver["startTime"] = moment(voiceOver.startTime)
          .set("year", 2017)
          .set("month", 0)
          .set("date", 1);
      } else {
        const { hours: startHours, minutes: startMinutes } = secondsToTime(
          voiceOver.startTime
        );
        editVoiceOver["startTime"] = moment()
          .set("year", 2017)
          .set("month", 0)
          .set("date", 1)
          .set("hours", startHours)
          .set("minutes", startMinutes);
      }
    }

    if (voiceOver.recurring) {
      if (moment.isDate(voiceOver.endTime)) {
        editVoiceOver["endTime"] = moment(voiceOver.endTime)
          .set("year", 2017)
          .set("month", 0)
          .set("date", 1);
      } else {
        const { hours: endHours, minutes: endMinutes } = secondsToTime(
          voiceOver.endTime
        );
        editVoiceOver["endTime"] = moment()
          .set("year", 2017)
          .set("month", 0)
          .set("date", 1)
          .set("hours", endHours)
          .set("minutes", endMinutes);
      }

      const { hours: frequencyHours, minutes: frequencyMinutes } =
        secondsToTime(voiceOver.frequency);
      editVoiceOver["hours"] = frequencyHours;
      editVoiceOver["minutes"] = frequencyMinutes;
    }

    this.setState({
      showPopupVoiceOver: true,
      newVoiceOver: editVoiceOver,
    });
  }

  prepareSchedules(schedules) {
    if (!schedules) return [];

    schedules.forEach((schedule) => {
      schedule.playMode = schedule.playMode || "random";
      schedule.deleted = false;
      if (schedule.playlist) {
        schedule.type = "playlist";
        schedule.playlist =
          this.props.playlists[
            this.state.playlistIndex.indexOf(schedule.playlist)
          ];
        schedule.playlistCollection = null;
        schedule.collectionMode = "full";
        schedule.partialSize = 5;
        schedule.name = schedule.name || "";
      } else {
        schedule.type = "collection";
        schedule.playlist = null;
        schedule.playlistCollection =
          this.props.collections[
            this.state.collectionIndex.indexOf(schedule.playlistCollection)
          ];
        schedule.collectionMode = schedule.collectionMode || "full";
        schedule.partialSize = schedule.partialSize || 5;
        schedule.name = schedule.name || "";
      }
    });

    return schedules;
  }

  buildCalendarEventList(schedules) {
    let eventList = [];
    if (!this.state.displaySchedule) return [];
    schedules.forEach((schedule, i) => {
      if (schedule.deleted) return;

      for (let key in schedule.activeDays) {
        if (schedule.activeDays[key] === true) {
          if (schedule.endTime >= 86400) schedule.endTime = 86399;

          let start = schedule.startTime + days.indexOf(key) * 86400;
          let end = schedule.endTime + days.indexOf(key) * 86400;
          let event = {
            ...schedule,
            startTime: new Date(
              2017,
              0,
              1,
              secondsToTime(start).hours,
              secondsToTime(start).minutes,
              0
            ),
            endTime: new Date(
              2017,
              0,
              1,
              secondsToTime(end).hours,
              secondsToTime(end).minutes,
              0
            ),
            existing: i,
          };
          eventList.push(event);
        }
      }
    });
    eventList = helpers.calculateLayoutByDay(eventList);
    return eventList;
  }

  buildCalendarVoiceOverList(voiceOvers) {
    let eventList = [];
    if (!voiceOvers?.length || !this.state.displayVoiceOver) return [];
    voiceOvers?.forEach((voiceOver, i) => {
      for (let key in voiceOver.activeDays) {
        if (voiceOver.activeDays[key] === true) {
          if (voiceOver.endTime >= 86400) voiceOver.endTime = 86399;
          if (!voiceOver.recurring) voiceOver.endTime = voiceOver.startTime;

          let start = voiceOver.startTime + days.indexOf(key) * 86400;
          let end = voiceOver.endTime + days.indexOf(key) * 86400;
          let event = {
            ...voiceOver,
            startTime: new Date(
              2017,
              0,
              1,
              secondsToTime(start).hours,
              secondsToTime(start).minutes,
              1
            ),
            endTime: new Date(
              2017,
              0,
              1,
              secondsToTime(end).hours,
              secondsToTime(end).minutes,
              0
            ),
            type: "voiceOver",
          };
          eventList.push(event);
        }
      }
    });

    eventList = helpers.calculateLayoutByDay(eventList);
    return eventList;
  }

  newEvent(e) {
    if (e.action === "click") {
      return;
    }
    let day = e.start.getDay();

    this.setState({
      showPopup: true,
      newEvent: {
        startTime: moment(e.start),
        endTime: moment(e.end),
        name: "",
        playlist: null,
        playlistCollection: null,
        playMode: "random",
        collectionMode: "full",
        hours: null,
        minutes: null,
        partialSize: 5,
        type: "playlist",
        activeDays: {
          monday: day === 1 ? true : false,
          tuesday: day === 2 ? true : false,
          wednesday: day === 3 ? true : false,
          thursday: day === 4 ? true : false,
          friday: day === 5 ? true : false,
          saturday: day === 6 ? true : false,
          sunday: day === 0 ? true : false,
        },
      },
    });
  }

  newVoiceOver() {
    const day = moment().get("day");
    this.setState({
      showPopupVoiceOver: true,
      newVoiceOver: {
        file: undefined,
        name: "",
        campaign: this.state.entity.id,
        startTime: moment(),
        endTime: undefined,
        recurring: false,
        frequency: 0,
        activeDays: {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
        },
      },
    });
  }

  openEvent(event) {
    if (event?.type !== "voiceOver") {
      let eventCopy = { ...event };
      eventCopy.startTime = moment(eventCopy.startTime);
      eventCopy.endTime = moment(eventCopy.endTime);
      eventCopy.name = event.name;

      this.setState({ newEvent: eventCopy, showPopup: true });
    }
    if (event?.type === "voiceOver") {
      this.handleEditVoiceOver(event);
    }
  }

  hidePopup() {
    this.setState({
      showPopup: false,
      showPopupRenameHidden: false,
      showPopupRename: false,
      showPopupManualPlaylist: false,
      showPopupVoiceOver: false,
    });
  }

  setEvent() {
    // Check content
    if (
      this.state.newEvent.type === "playlist" &&
      !this.state.newEvent.playlist
    )
      return toast.error("Please select a playlist");

    if (
      this.state.newEvent.type === "collection" &&
      !this.state.newEvent.playlistCollection
    )
      return toast.error("Please select a collection");

    let days = 0;
    for (let key in this.state.newEvent.activeDays)
      if (this.state.newEvent.activeDays[key]) days++;
    if (days < 1) return toast.error("Select at least one day");

    let startTime = this.state.newEvent.startTime.toDate();
    let endTime = this.state.newEvent.endTime.toDate();

    let event = {
      ...this.state.newEvent,
      startTime: startTime.getHours() * 60 * 60 + startTime.getMinutes() * 60,
      endTime: endTime.getHours() * 60 * 60 + endTime.getMinutes() * 60,
    };

    // Clean data
    if (event.type === "playlist") {
      event.playlistCollection = null;
      event.collectionMode = "full";
      event.partialSize = 5;
    } else {
      event.playlist = null;
    }

    let newSchedules = [...(this.state.schedules || [])];
    if (this.state.newEvent.existing || this.state.newEvent.existing === 0) {
      newSchedules[this.state.newEvent.existing] = event;
    } else {
      newSchedules = newSchedules.concat(event);
    }

    this.setState({
      schedules: newSchedules,
      showPopup: false,
      formHasChanged: true,
    });

    // Update live playlists
    let newLivePlaylists = [];
    this.state.schedules.map((schedule) => {
      if (!schedule.deleted || schedule.deleted == false) {
        if (schedule.playlist) {
          this.props.playlists.map((playlist) => {
            if (playlist.id == schedule.playlist.id) {
              newLivePlaylists.push(playlist);
            }
          });
        }

        if (schedule.playlistCollection) {
          this.props.collections.map((collection) => {
            if (collection.id == schedule.playlistCollection.id) {
              newLivePlaylists = newLivePlaylists.concat(collection.playlists);
            }
          });
        }
      }
    });
    if (!this.state.newEvent.deleted || this.state.newEvent.deleted == false) {
      if (this.state.newEvent.playlist) {
        this.props.playlists.map((playlist) => {
          if (playlist.id == this.state.newEvent.playlist.id) {
            newLivePlaylists.push(playlist);
          }
        });
      }

      if (this.state.newEvent.playlistCollection) {
        this.props.collections.map((collection) => {
          if (collection.id == this.state.newEvent.playlistCollection.id) {
            newLivePlaylists = newLivePlaylists.concat(collection.playlists);
          }
        });
      }
    }

    // Remove duplicate object
    let tmpLivePlaylists = [];
    let obj = {};
    newLivePlaylists?.map((newLivePlaylist) => {
      if (!obj[newLivePlaylist.id]) {
        tmpLivePlaylists.push(newLivePlaylist);
        obj[newLivePlaylist.id] = true;

        let livePlaylist = {};
        let found = false;
        this.state.entity?.manualPlaylistSettings?.map(
          (manualPlaylistSetting) => {
            if (manualPlaylistSetting.playlist == newLivePlaylist.id) {
              found = true;
              livePlaylist.name = manualPlaylistSetting.name;
              livePlaylist.hidden = manualPlaylistSetting.hidden;
              livePlaylist.playlist = manualPlaylistSetting.playlist;
            }
          }
        );
        if (found == false) {
          livePlaylist.name = newLivePlaylist.name;
          livePlaylist.playlist = newLivePlaylist.id;
        }
        tmpLivePlaylists.push(livePlaylist);
        obj[newLivePlaylist.id] = true;
      }
    });

    this.props.livePlaylists.entities = tmpLivePlaylists;
  }

  openManualPlaylistName(e) {
    let found = false;
    this.props.entity.manualPlaylistSettings.map((manualPlaylistSetting) => {
      if (e.id == manualPlaylistSetting.playlist) {
        found = true;
        this.setState({
          renameManualPlaylist: {
            name: manualPlaylistSetting.name,
            playlist: manualPlaylistSetting.playlist,
          },
          showPopupRename: true,
        });
      }
    });
    if (!found) {
      this.setState({
        renameManualPlaylist: {
          name: e.name,
          playlist: e.id,
        },
        showPopupRename: true,
      });
    }
  }

  setManualPlaylistName() {
    // Check content
    if (
      this.state.renameManualPlaylist.name === "" &&
      !this.state.renameManualPlaylist.name
    )
      return toast.error("Please enter a playlist name");

    // Update compaign manual playlist settings
    let found = false;
    let newManualPlaylistSettings = this.state.entity.manualPlaylistSettings;
    newManualPlaylistSettings.forEach((e) => {
      if (e.playlist == this.state.renameManualPlaylist.playlist) {
        found = true;
        e.name = this.state.renameManualPlaylist.name;
      }
    });
    if (!found) {
      newManualPlaylistSettings.push(this.state.renameManualPlaylist);
    }

    // Update compaign manual playlist table
    found = false;
    this.props.manualPlaylists.entities.forEach((e) => {
      if (e.id == this.state.renameManualPlaylist.playlist) {
        found = true;
        e.name = this.state.renameManualPlaylist.name;
      }
    });
    // if (!found) {
    // 	this.props.manualPlaylists.entities.push(this.state.renameManualPlaylist)
    // }

    // Update compaign live playlist table
    found = false;
    this.props.livePlaylists.entities.forEach((e) => {
      if (e.playlist == this.state.renameManualPlaylist.playlist) {
        found = true;
        e.name = this.state.renameManualPlaylist.name;
        e.hidden = this.state.renameManualPlaylist.hidden;
      }
    });

    let newEntity = this.state.entity;
    newEntity.manualPlaylistSettings = newManualPlaylistSettings;

    this.setState({
      entity: newEntity,
      showPopupRename: false,
      formHasChanged: true,
    });
  }

  openLivePlaylistName(e) {
    let found = false;
    this.props.entity.manualPlaylistSettings.map((manualPlaylistSetting) => {
      if (e.playlist == manualPlaylistSetting.playlist) {
        found = true;
        this.setState({
          renameLivePlaylist: {
            name: manualPlaylistSetting.name,
            playlist: manualPlaylistSetting.playlist,
            hidden: manualPlaylistSetting.hidden,
          },
          showPopupRenameHidden: true,
        });
      }
    });
    if (!found) {
      this.setState({
        renameLivePlaylist: {
          name: e.name,
          playlist: e.playlist,
          hidden: false,
        },
        showPopupRenameHidden: true,
      });
    }
  }

  setLivePlaylistName() {
    // Check content
    if (
      this.state.renameLivePlaylist.name === "" &&
      !this.state.renameLivePlaylist.name
    )
      return toast.error("Please enter a playlist name");

    // Update compaign live playlist table
    let found = false;
    this.props.livePlaylists.entities.forEach((e) => {
      if (e.playlist == this.state.renameLivePlaylist.playlist) {
        found = true;
        e.name = this.state.renameLivePlaylist.name;
        e.hidden = this.state.renameLivePlaylist.hidden;
      }
    });

    // Update compaign manual playlist settings
    found = false;
    let newManualPlaylistSettings = this.state.entity.manualPlaylistSettings;
    newManualPlaylistSettings.forEach((e) => {
      if (e.playlist == this.state.renameLivePlaylist.playlist) {
        found = true;
        e.name = this.state.renameLivePlaylist.name;
        e.hidden = this.state.renameLivePlaylist.hidden;
      }
    });
    if (!found) {
      newManualPlaylistSettings.push(this.state.renameLivePlaylist);
    }

    // Update compaign manual playlist table
    found = false;
    this.props.manualPlaylists.entities.forEach((e) => {
      if (e.id == this.state.renameLivePlaylist.playlist) {
        found = true;
        e.name = this.state.renameLivePlaylist.name;
        e.hidden = this.state.renameLivePlaylist.hidden;
      }
    });

    let newEntity = this.state.entity;
    newEntity.manualPlaylistSettings = newManualPlaylistSettings;

    this.setState({
      entity: newEntity,
      showPopupRenameHidden: false,
      formHasChanged: true,
    });
  }

  handleStartTimeChange = (value) => {
    this.setState({ newEvent: { ...this.state.newEvent, startTime: value } });
  };

  handleEndTimeChange = (value) => {
    this.setState({ newEvent: { ...this.state.newEvent, endTime: value } });
  };

  handlePlaylistChange = (selectedOption) => {
    this.setState(
      { newEvent: { ...this.state.newEvent, playlist: selectedOption } },
      () => console.log(`Option selected:`, selectedOption)
    );
  };

  handleCollectionChange = (selectedOption) => {
    this.setState(
      {
        newEvent: {
          ...this.state.newEvent,
          playlistCollection: selectedOption,
        },
      },
      () => console.log(`Option selected:`, selectedOption)
    );
  };

  handleDaysChange = (day) => {
    this.setState({
      newEvent: {
        ...this.state.newEvent,
        activeDays: {
          ...this.state.newEvent.activeDays,
          [day]: !this.state.newEvent.activeDays[day],
        },
      },
    });
  };

  handlePlaylistTitleChange = (e) => {
    this.setState({
      newEvent: {
        ...this.state.newEvent,
        name: e.target.value,
      },
    });
  };

  handlePlayMode = (e) => {
    this.setState({
      newEvent: {
        ...this.state.newEvent,
        playMode: e.target.value,
      },
    });
  };

  handleSmartPlaySubsectionHour = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex
    if (e.target.value === "" || re.test(e.target.value))
      this.setState({
        newEvent: {
          ...this.state.newEvent,
          smartPlaySubsectionHour: e.target.value,
        },
      });
  };

  handleSmartPlayRefreshPeriod = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex
    if (e.target.value === "" || re.test(e.target.value))
      this.setState({
        newEvent: {
          ...this.state.newEvent,
          smartPlayRefreshPeriod: e.target.value,
        },
      });
  };

  handleSmartPlayRefreshPercent = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex
    if (e.target.value === "" || re.test(e.target.value))
      if (e.target.value < 1)
        // percent must not be less than 1% and more than 100%
        e.target.value = 1;
    if (e.target.value > 100) e.target.value = 100;

    this.setState({
      newEvent: {
        ...this.state.newEvent,
        smartPlayRefreshPercent: e.target.value,
      },
    });
  };

  handleCollectionMode = (e) => {
    this.setState({
      newEvent: {
        ...this.state.newEvent,
        collectionMode: e.target.value,
      },
    });
  };

  switchTab = (e, tab) => {
    e.preventDefault();
    this.setState({ newEvent: { ...this.state.newEvent, type: tab } });
  };

  handlePartialSize = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex
    if (e.target.value === "" || re.test(e.target.value))
      this.setState({
        newEvent: {
          ...this.state.newEvent,
          partialSize: e.target.value,
        },
      });
  };

  deleteEvent = (e) => {
    this.setState(
      {
        newEvent: { ...this.state.newEvent, deleted: true },
      },
      () => this.setEvent()
    );
  };

  shouldBlockNavigation = () => {
    if (this.state.formHasChanged) return true;
    return false;
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => {
        var r = window.confirm(
          "You have unsaved changes, are you sure you want to leave?"
        );
        if (r === false) return;
      };
    } else {
      window.onbeforeunload = undefined;
    }

    if (
      JSON.stringify(prevState.schedules) !==
        JSON.stringify(this.state.schedules) ||
      JSON.stringify(prevProps.voiceOvers?.entities) !==
        JSON.stringify(this.props.voiceOvers?.entities) ||
      prevState.displaySchedule !== this.state.displaySchedule ||
      prevState.displayVoiceOver !== this.state.displayVoiceOver
    ) {
      const calendarEvents = [
        ...this.buildCalendarEventList(this.state.schedules),
        ...this.buildCalendarVoiceOverList(this.props.voiceOvers?.entities),
      ];
      this.setState({ calendarEvents });
    }
  };

  addPlaylist = (e) => {
    e.preventDefault();
    if (!this.state.selectedPlaylist)
      return toast.error("Please select a playlist");

    let found = false;
    this.props.manualPlaylists.entities.forEach((e) => {
      if (e.id == this.state.selectedPlaylist.id) found = true;
    });
    if (found) return toast.error("Playlist already added");

    // add playlist into array
    this.props.manualPlaylists.entities.push(this.state.selectedPlaylist);
    this.setState({
      showPopupManualPlaylist: false,
    });
  };

  handleManualPlaylistChange = (selectedOption) => {
    this.setState({ selectedPlaylist: selectedOption }, () =>
      console.log(`Option selected:`, selectedOption)
    );
  };

  handleManualPlaylistName = (e) => {
    this.setState({
      renameManualPlaylist: {
        ...this.state.renameManualPlaylist,
        name: e.target.value,
      },
    });
  };

  handleLivePlaylistName = (e) => {
    this.setState({
      renameLivePlaylist: {
        ...this.state.renameLivePlaylist,
        name: e.target.value,
      },
    });
  };

  onChangeAssetFile(event) {
    event.stopPropagation();
    event.preventDefault();

    if (event.target.files.length < 1) return;

    const newVoiceOver = { ...this.state.newVoiceOver };
    set(newVoiceOver, "file", event.target.files?.[0]);
    this.setState({ newVoiceOver });
  }

  handleVoiceOverFieldChange(key, value) {
    let localVal = value;
    if ((key === "startTime" || key === "endTime") && moment.isMoment(value)) {
      localVal.set("year", 2017).set("month", 0).set("date", 1);
    }
    const newVoiceOver = { ...this.state.newVoiceOver };
    set(newVoiceOver, key, value);
    this.setState({ newVoiceOver });
  }

  saveVoiceOver(force) {
    const { newVoiceOver } = this.state;
    if (!newVoiceOver.name?.trim()) return toast.error("Please enter a name");
    const params = {
      name: newVoiceOver.name?.trim(),
      campaign: newVoiceOver.campaign,
      recurring: newVoiceOver.recurring,
      frequency: newVoiceOver.frequency,
      activeDays: newVoiceOver.activeDays,
    };

    let validActiveDays = false;
    Object.keys(newVoiceOver.activeDays).forEach((key) => {
      if (newVoiceOver.activeDays[key]) validActiveDays = true;
    });
    if (!validActiveDays) return toast.error("Please select at least one day");

    if (!newVoiceOver?.file) return toast.error("Please upload a file");

    let frequency =
      (newVoiceOver.hours || 0) * 60 * 60 + (newVoiceOver.minutes || 0) * 60;
    if (newVoiceOver.recurring && frequency < 1)
      return toast.error("Please enter a valid frequency");
    params["frequency"] = frequency;
    if (
      newVoiceOver.recurring &&
      (!newVoiceOver.endTime ||
        moment(newVoiceOver?.endTime).isBefore(moment(newVoiceOver.startTime)))
    ) {
      return toast.error("Please enter a valid end time");
    }
    let endTime = newVoiceOver?.endTime?.toDate();
    params["endTime"] = endTime
      ? endTime.getHours() * 60 * 60 + endTime.getMinutes() * 60
      : null;
    if (!newVoiceOver?.startTime)
      return toast.error("Please enter a valid start time");
    let startTime = newVoiceOver.startTime.toDate();
    params["startTime"] =
      startTime.getHours() * 60 * 60 + startTime.getMinutes() * 60;

    const file = newVoiceOver.file;

    if (newVoiceOver.id) {
      API.updateVoiceOver(
        newVoiceOver.id,
        {
          ...params,
          id: newVoiceOver.id,
          hash: newVoiceOver.hash,
          file,
          bitRate: newVoiceOver.bitRate,
          sampleRate: newVoiceOver.sampleRate,
          duration: newVoiceOver.duration,
        },
        force
      )
        .then((response) => {
          this.setState({ showPopupVoiceOver: false });
          if (this.props.onUpdateVoiceOver) {
            this.props.onUpdateVoiceOver(response);
          }
        })
        .catch((error) => {
          this.handleErrorUpdateOrPostVoiceOver(error);
        });
    } else {
      API.postVoiceOver(params, file, force)
        .then((response) => {
          this.setState({ showPopupVoiceOver: false });

          if (this.props.onAddVoiceOver) {
            this.props.onAddVoiceOver(response);
          }
        })
        .catch((error) => {
          this.handleErrorUpdateOrPostVoiceOver(error);
        });
    }
  }

  handleErrorUpdateOrPostVoiceOver(error) {
    if (error.response.status === 400) {
      const errorMsg = error?.response?.data?.errors?.[0];
      if (typeof errorMsg === "string") {
        alert(errorMsg);
        return;
      }
      const conflictCampaign =
        error?.response?.data?.errors?.[0]?.results?.[0]?.campaigns?.name;
      const conflictDeviceSku = error?.response?.data?.errors?.[0]?.device?.sku;
      if (conflictCampaign && conflictDeviceSku) {
        var r = window.confirm(
          `You have scheduled another voice over to be played at the same time on the same device(${conflictDeviceSku}), from the campaign ${conflictCampaign}. Are you sure you want to save this voice over?`
        );
        if (r) {
          this.saveVoiceOver(true);
        }
      }
    }
  }

  handleCalendarChange(e, type) {
    switch (type) {
      case "schedule":
        this.setState({ displaySchedule: e.target.checked });
        break;
      case "voice-over":
        this.setState({ displayVoiceOver: e.target.checked });
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <>
        <React.Fragment>
          <Prompt
            when={this.shouldBlockNavigation()}
            message="You have unsaved changes, are you sure you want to leave?"
          />

          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.save(this.state.entity, this.state.schedules);
            }}
            className="campaign-form"
          >
            <div className="actions clearfix">
              <div className="buttons clearfix">
                <button
                  className="grey"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.cancel();
                  }}
                >
                  Cancel
                </button>
                <button className="blue">Save campaign</button>
              </div>
            </div>

            <div className="block above">
              <h2>Details</h2>
              <div className="fields clearfix">
                <div className="field">
                  <label>Campaign name</label>
                  <input
                    type="text"
                    value={this.state.entity.name}
                    onChange={(e) =>
                      this.setState({
                        entity: { ...this.state.entity, name: e.target.value },
                        formHasChanged: true,
                      })
                    }
                  />
                </div>
                <div className="field">
                  <label>Priority level</label>
                  <select
                    value={this.state.entity.priority}
                    onChange={(e) =>
                      this.setState({
                        entity: {
                          ...this.state.entity,
                          priority: e.target.value,
                        },
                        formHasChanged: true,
                      })
                    }
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
                <div className="field">
                  <label>Infinite date</label>
                  <div className="radio">
                    <input
                      type="radio"
                      value="1"
                      name="date-range"
                      checked={this.state.entity.infinite === true}
                      onChange={() =>
                        this.setState({
                          entity: { ...this.state.entity, infinite: true },
                          formHasChanged: true,
                        })
                      }
                    />
                    <span>Yes</span>
                    <input
                      type="radio"
                      value="0"
                      name="date-range"
                      checked={this.state.entity.infinite === false}
                      onChange={() =>
                        this.setState({
                          entity: { ...this.state.entity, infinite: false },
                          formHasChanged: true,
                        })
                      }
                    />
                    <span>No</span>
                  </div>
                </div>
                {this.state.entity.infinite === false && (
                  <div className="field">
                    <label></label>
                    <DateRangePicker
                      startDate={this.state.entity.startDate}
                      startDateId="your_unique_start_date_id"
                      endDate={this.state.entity.endDate}
                      endDateId="your_unique_end_date_id"
                      onDatesChange={({ startDate, endDate }) => {
                        this.setState({
                          entity: {
                            ...this.state.entity,
                            startDate: startDate,
                            endDate: endDate,
                          },
                          formHasChanged: true,
                        });
                      }}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={(focusedInput) =>
                        this.setState({ focusedInput })
                      }
                      isOutsideRange={() => false}
                    />
                  </div>
                )}
              </div>
            </div>

            {this.state.entity.id && (
              <div className="flex-end-block">
                <div className="block-unit">
                  <input
                    type="checkbox"
                    checked={this.state.displaySchedule}
                    onChange={(e) => this.handleCalendarChange(e, "schedule")}
                  />
                  <label>Playlist</label>
                </div>

                <div className="block-unit">
                  <input
                    type="checkbox"
                    checked={this.state.displayVoiceOver}
                    onChange={(e) => this.handleCalendarChange(e, "voice-over")}
                  />
                  <label>Voice over</label>
                </div>
              </div>
            )}

            <div className="block scheduler-wrapper">
              <div>
                <Calendar
                  selectable
                  localizer={localizer}
                  events={this.state.calendarEvents}
                  startAccessor="startTime"
                  endAccessor="endTime"
                  style={{ height: 500 }}
                  defaultView={Views.WEEK}
                  toolbar={false}
                  formats={formats}
                  views={["week"]}
                  defaultDate={new Date(2017, 0, 1)}
                  onSelectSlot={this.newEvent.bind(this)}
                  onSelectEvent={this.openEvent.bind(this)}
                  components={{
                    eventWrapper: CustomEvent,
                  }}
                />
              </div>
            </div>

            <div className="wrapper">
              <h2>Live playlists</h2>
              <Table settings={this.props.livePlaylists} />
            </div>

            <div className="wrapper">
              <h2>Manual playlists</h2>
              <Table settings={this.props.manualPlaylists} />
            </div>

            {this.props?.voiceOvers && (
              <div className="wrapper">
                <h2>Voice over</h2>
                <Table settings={this.props.voiceOvers} />
              </div>
            )}

            <div className="wrapper">
              <h2>Associated devices</h2>
              <Table settings={this.props.devices} />
            </div>
          </form>

          <Rodal
            visible={this.state.showPopup}
            onClose={this.hidePopup.bind(this)}
            width={790}
            height={630}
          >
            <div className="header"></div>
            <div className="columns">
              <div className="left">
                <div className="tabs">
                  <div
                    className={
                      "tab" +
                      (this.state.newEvent.type === "playlist" ? " active" : "")
                    }
                    onClick={(e) => this.switchTab(e, "playlist")}
                  >
                    Playlist
                  </div>
                  <div
                    className={
                      "tab" +
                      (this.state.newEvent.type === "collection"
                        ? " active"
                        : "")
                    }
                    onClick={(e) => this.switchTab(e, "collection")}
                  >
                    Collection
                  </div>
                </div>

                <h2>Choose a playlist</h2>
                <div className="field">
                  {this.state.newEvent.type === "playlist" ? (
                    <Select
                      value={this.state.newEvent.playlist}
                      onChange={this.handlePlaylistChange}
                      options={this.props.playlists}
                      getOptionLabel={(playlist) => playlist.name}
                      getOptionValue={(playlist) => playlist.id}
                    />
                  ) : (
                    <Select
                      value={this.state.newEvent.playlistCollection}
                      onChange={this.handleCollectionChange}
                      options={this.props.collections}
                      getOptionLabel={(collections) => collections.name}
                      getOptionValue={(collections) => collections.id}
                    />
                  )}
                </div>

                <h4>Name on the player</h4>
                <div className="field">
                  <label>Name</label>
                  <input
                    type="text"
                    value={this.state.newEvent.name}
                    onChange={this.handlePlaylistTitleChange}
                  />
                </div>

                <h4>Play mode</h4>
                <div className="field">
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        name="play-mode"
                        value="random"
                        checked={this.state.newEvent.playMode === "random"}
                        onChange={this.handlePlayMode}
                      />
                      <span>Random</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="play-mode"
                        value="normal"
                        checked={this.state.newEvent.playMode === "normal"}
                        onChange={this.handlePlayMode}
                      />
                      <span>Normal</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="play-mode"
                        value="smart_play"
                        checked={this.state.newEvent.playMode === "smart_play"}
                        onChange={this.handlePlayMode}
                      />
                      <span>Smart Play</span>
                    </label>
                  </div>
                </div>

                {this.state.newEvent.playMode === "smart_play" && (
                  <>
                    <h4>Smart mode options</h4>
                    <div className="field">
                      <label>
                        <span className="partial">
                          Take{" "}
                          <input
                            type="text"
                            value={this.state.newEvent.smartPlaySubsectionHour}
                            onChange={this.handleSmartPlaySubsectionHour}
                          />{" "}
                          hours per playlist
                        </span>
                      </label>
                      <label>
                        <span className="partial">
                          Update playlist every{" "}
                          <input
                            type="text"
                            value={this.state.newEvent.smartPlayRefreshPeriod}
                            onChange={this.handleSmartPlayRefreshPeriod}
                          />{" "}
                          months
                        </span>
                      </label>
                      <label>
                        <span className="partial">
                          Update{" "}
                          <input
                            type="text"
                            value={this.state.newEvent.smartPlayRefreshPercent}
                            onChange={this.handleSmartPlayRefreshPercent}
                          />{" "}
                          % each time
                        </span>
                      </label>
                    </div>
                  </>
                )}

                {this.state.newEvent.type === "collection" && (
                  <>
                    <h4>Collection mode</h4>
                    <div className="field">
                      <div className="radio">
                        <label>
                          <input
                            type="radio"
                            name="collection-mode"
                            value="full"
                            checked={
                              this.state.newEvent.collectionMode === "full"
                            }
                            onChange={this.handleCollectionMode}
                          />
                          <span>Full</span>
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="collection-mode"
                            value="partial"
                            checked={
                              this.state.newEvent.collectionMode === "partial"
                            }
                            onChange={this.handleCollectionMode}
                          />
                          <span>Partial</span>
                        </label>
                        {this.state.newEvent.collectionMode === "partial" && (
                          <span className="partial">
                            Play{" "}
                            <input
                              type="text"
                              value={this.state.newEvent.partialSize}
                              onChange={this.handlePartialSize}
                            />{" "}
                            songs per playlist
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="right">
                <h2>Schedule</h2>

                <h4>Time</h4>
                <div className="field">
                  <TimePicker
                    showSecond={false}
                    value={this.state.newEvent.startTime}
                    onChange={this.handleStartTimeChange}
                  />
                  <TimePicker
                    showSecond={false}
                    value={this.state.newEvent.endTime}
                    onChange={this.handleEndTimeChange}
                  />
                </div>

                <h4>Weekly schedule</h4>
                <div className="field">
                  <div className="checkbox">
                    <label onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={this.state.newEvent.activeDays.sunday}
                        onChange={() => this.handleDaysChange("sunday")}
                      />
                      <span></span>Sunday
                    </label>
                  </div>
                  <div className="checkbox">
                    <label onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={this.state.newEvent.activeDays.monday}
                        onChange={() => this.handleDaysChange("monday")}
                      />
                      <span></span>Monday
                    </label>
                  </div>
                  <div className="checkbox">
                    <label onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={this.state.newEvent.activeDays.tuesday}
                        onChange={() => this.handleDaysChange("tuesday")}
                      />
                      <span></span>Tuesday
                    </label>
                  </div>
                  <div className="checkbox">
                    <label onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={this.state.newEvent.activeDays.wednesday}
                        onChange={() => this.handleDaysChange("wednesday")}
                      />
                      <span></span>Wednesday
                    </label>
                  </div>
                  <div className="checkbox">
                    <label onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={this.state.newEvent.activeDays.thursday}
                        onChange={() => this.handleDaysChange("thursday")}
                      />
                      <span></span>Thursday
                    </label>
                  </div>
                  <div className="checkbox">
                    <label onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={this.state.newEvent.activeDays.friday}
                        onChange={() => this.handleDaysChange("friday")}
                      />
                      <span></span>Friday
                    </label>
                  </div>
                  <div className="checkbox">
                    <label onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={this.state.newEvent.activeDays.saturday}
                        onChange={() => this.handleDaysChange("saturday")}
                      />
                      <span></span>Saturday
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="actions">
              <button className="blue" onClick={this.setEvent.bind(this)}>
                Save
              </button>
              <button
                className="transparent"
                onClick={this.hidePopup.bind(this)}
              >
                Cancel
              </button>
              <button className="red" onClick={this.deleteEvent.bind(this)}>
                Delete
              </button>
            </div>
          </Rodal>

          <Rodal
            visible={this.state.showPopupManualPlaylist}
            onClose={this.hidePopup.bind(this)}
            width={790}
            height={574}
          >
            <div className="popup-inner">
              <div className="header"></div>
              <h2>Select a playlist</h2>
              <Select
                value={this.state.selectedPlaylist}
                onChange={this.handleManualPlaylistChange}
                options={this.props.playlists}
                getOptionLabel={(playlist) => playlist.name}
                getOptionValue={(playlist) => playlist.id}
              />
              <div className="actions">
                <button className="blue" onClick={this.addPlaylist.bind(this)}>
                  Save
                </button>
                <button
                  className="transparent"
                  onClick={this.hidePopup.bind(this)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Rodal>

          <Rodal
            visible={this.state.showPopupRename}
            onClose={this.hidePopup.bind(this)}
            width={790}
            height={300}
          >
            <div className="popup-inner">
              <div className="header"></div>
              <h2>Edit playlist name</h2>

              <div className="field">
                <label>Playlist name</label>
                <input
                  type="text"
                  value={this.state.renameManualPlaylist.name}
                  onChange={this.handleManualPlaylistName}
                />
              </div>

              <div className="actions">
                <button
                  className="blue"
                  onClick={this.setManualPlaylistName.bind(this)}
                >
                  Save
                </button>
                <button
                  className="transparent"
                  onClick={this.hidePopup.bind(this)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Rodal>

          <Rodal
            visible={this.state.showPopupRenameHidden}
            onClose={this.hidePopup.bind(this)}
            width={790}
            height={348}
          >
            <div className="popup-inner">
              <div className="header"></div>
              <h2>Edit playlist name</h2>

              <div className="field">
                <label>Playlist name</label>
                <input
                  type="text"
                  value={this.state.renameLivePlaylist.name}
                  onChange={this.handleLivePlaylistName}
                />
              </div>

              <div className="field">
                <label>Hidden in manual</label>
                <div className="radio">
                  <input
                    type="radio"
                    value="0"
                    name="hidden"
                    checked={this.state.renameLivePlaylist.hidden === true}
                    onChange={() =>
                      this.setState({
                        renameLivePlaylist: {
                          ...this.state.renameLivePlaylist,
                          hidden: true,
                        },
                        formHasChanged: true,
                      })
                    }
                  />
                  <span>True</span>
                  <input
                    type="radio"
                    value="1"
                    name="hidden"
                    checked={this.state.renameLivePlaylist.hidden !== true}
                    onChange={() =>
                      this.setState({
                        renameLivePlaylist: {
                          ...this.state.renameLivePlaylist,
                          hidden: false,
                        },
                        formHasChanged: true,
                      })
                    }
                  />
                  <span>False</span>
                </div>
              </div>

              <div className="actions">
                <button
                  className="blue"
                  onClick={this.setLivePlaylistName.bind(this)}
                >
                  Save
                </button>
                <button
                  className="transparent"
                  onClick={this.hidePopup.bind(this)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Rodal>

          <Rodal
            visible={this.state.showPopupVoiceOver}
            onClose={this.hidePopup.bind(this)}
            width={790}
            height={590}
          >
            <div className="header"></div>
            <div className="columns-mini">
              <div className="left">
                <h2>
                  {this.state.newVoiceOver.id
                    ? "Edit voice over"
                    : "Create voice over"}
                </h2>
                <div className="field">
                  <label>Voice over name</label>
                  <input
                    type="text"
                    value={this.state.newVoiceOver.name}
                    onChange={(e) =>
                      this.handleVoiceOverFieldChange("name", e.target.value)
                    }
                  />
                </div>
                <h4>UPLOADED FILE</h4>
                <input
                  type="file"
                  ref={(ref) => (this.uploadAsset = ref)}
                  style={{ display: "none" }}
                  value=""
                  onChange={this.onChangeAssetFile.bind(this)}
                  accept=".mp3"
                />
                <div className="field">
                  {Boolean(this.state.newVoiceOver.file) ? (
                    <div className="audio-file-display">
                      <div className="name">
                        {this.state.newVoiceOver?.file?.name}
                      </div>
                      {this.state.newVoiceOver?.duration && (
                        <div className="duration">
                          {(() => {
                            let duration = this.state.newVoiceOver.duration;
                            if (!isNumber(duration)) return "( - )";
                            let hours = Math.floor(duration / 60 / 60);
                            let minutes =
                              Math.floor(duration / 60) - hours * 60;
                            let seconds = Math.round(duration % 60);
                            if (seconds < 10)
                              seconds = "0" + Math.round(seconds);
                            return "(" + minutes + ":" + seconds + ")";
                          })()}
                        </div>
                      )}
                      {this.state.newVoiceOver?.file?.uri && (
                        <PlayButton
                          {...this.props}
                          newVoiceOver={this.state.newVoiceOver}
                        />
                      )}
                      {/* <div className="delete-button">
                        <i
                          onClick={() =>
                            this.handleVoiceOverFieldChange("file", undefined)
                          }
                          className="icon-trash"
                        ></i>
                      </div> */}
                    </div>
                  ) : (
                    <button
                      className="blue add"
                      onClick={() => this.uploadAsset.click()}
                    >
                      Upload file
                    </button>
                  )}
                </div>
                <h4>RECURRING</h4>
                <div className="field">
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        name="voice-over-recurring"
                        value={false}
                        checked={this.state.newVoiceOver.recurring === false}
                        onChange={() =>
                          this.handleVoiceOverFieldChange("recurring", false)
                        }
                      />
                      <span>No</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="voice-over-recurring"
                        value={true}
                        checked={this.state.newVoiceOver.recurring === true}
                        onChange={() =>
                          this.handleVoiceOverFieldChange("recurring", true)
                        }
                      />
                      <span>Yes</span>
                    </label>
                  </div>
                  {this.state.newVoiceOver?.recurring && (
                    <div className="field">
                      <label>Repeat every</label>
                      <div className="two-columns-small">
                        <div className="two-columns-unit">
                          <input
                            value={this.state.newVoiceOver.hours}
                            onChange={(e) =>
                              this.handleVoiceOverFieldChange(
                                "hours",
                                e.target.value
                              )
                            }
                            type="number"
                            min={0}
                            max={23}
                          ></input>
                          <span>hours</span>
                        </div>
                        <div className="two-columns-unit">
                          <input
                            value={this.state.newVoiceOver.minutes}
                            onChange={(e) =>
                              this.handleVoiceOverFieldChange(
                                "minutes",
                                e.target.value
                              )
                            }
                            type="number"
                            min={0}
                            max={59}
                          ></input>
                          <span>minutes</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="right">
                <h2>Schedule</h2>

                <h4>Time</h4>
                <div style={{ display: "flex" }}>
                  <div className="field full">
                    <label>Start</label>
                    <TimePicker
                      showSecond={false}
                      value={this.state.newVoiceOver.startTime}
                      onChange={(value) =>
                        this.handleVoiceOverFieldChange("startTime", value)
                      }
                    />
                  </div>
                  <div className="field full">
                    <label>End</label>
                    <TimePicker
                      showSecond={false}
                      disabled={this.state.newVoiceOver.recurring === false}
                      value={this.state.newVoiceOver.endTime}
                      onChange={(value) =>
                        this.handleVoiceOverFieldChange("endTime", value)
                      }
                    />
                  </div>
                </div>

                <h4>Weekly schedule</h4>
                <div className="field">
                  <div className="checkbox">
                    <label onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={this.state.newVoiceOver.activeDays.sunday}
                        onChange={() =>
                          this.handleVoiceOverFieldChange(
                            "activeDays.sunday",
                            !this.state.newVoiceOver.activeDays.sunday
                          )
                        }
                      />
                      <span></span>Sunday
                    </label>
                  </div>
                  <div className="checkbox">
                    <label onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={this.state.newVoiceOver.activeDays.monday}
                        onChange={() =>
                          this.handleVoiceOverFieldChange(
                            "activeDays.monday",
                            !this.state.newVoiceOver.activeDays.monday
                          )
                        }
                      />
                      <span></span>Monday
                    </label>
                  </div>
                  <div className="checkbox">
                    <label onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={this.state.newVoiceOver.activeDays.tuesday}
                        onChange={() =>
                          this.handleVoiceOverFieldChange(
                            "activeDays.tuesday",
                            !this.state.newVoiceOver.activeDays.tuesday
                          )
                        }
                      />
                      <span></span>Tuesday
                    </label>
                  </div>
                  <div className="checkbox">
                    <label onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={this.state.newVoiceOver.activeDays.wednesday}
                        onChange={() =>
                          this.handleVoiceOverFieldChange(
                            "activeDays.wednesday",
                            !this.state.newVoiceOver.activeDays.wednesday
                          )
                        }
                      />
                      <span></span>Wednesday
                    </label>
                  </div>
                  <div className="checkbox">
                    <label onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={this.state.newVoiceOver.activeDays.thursday}
                        onChange={() =>
                          this.handleVoiceOverFieldChange(
                            "activeDays.thursday",
                            !this.state.newVoiceOver.activeDays.thursday
                          )
                        }
                      />
                      <span></span>Thursday
                    </label>
                  </div>
                  <div className="checkbox">
                    <label onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={this.state.newVoiceOver.activeDays.friday}
                        onChange={() =>
                          this.handleVoiceOverFieldChange(
                            "activeDays.friday",
                            !this.state.newVoiceOver.activeDays.friday
                          )
                        }
                      />
                      <span></span>Friday
                    </label>
                  </div>
                  <div className="checkbox">
                    <label onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={this.state.newVoiceOver.activeDays.saturday}
                        onChange={() =>
                          this.handleVoiceOverFieldChange(
                            "activeDays.saturday",
                            !this.state.newVoiceOver.activeDays.saturday
                          )
                        }
                      />
                      <span></span>Saturday
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="actions">
              <button
                className="blue"
                onClick={() => this.saveVoiceOver(false)}
              >
                Save
              </button>
              <button
                className="transparent"
                onClick={this.hidePopup.bind(this)}
              >
                Cancel
              </button>
              {this.state.newVoiceOver?.id && (
                <button
                  className="red"
                  onClick={() => {
                    if (this.props.onDeleteVoiceOver) {
                      this.props.onDeleteVoiceOver(this.state.newVoiceOver.id);
                    }
                    this.hidePopup();
                  }}
                >
                  Delete
                </button>
              )}
            </div>
          </Rodal>
        </React.Fragment>
      </>
    );
  }
}

export default CampaignForm;

const CustomEvent = (props) => {
  const { event } = props;
  const durationDisplay = (duration) => {
    if (duration) {
      const { hours, minutes } = secondsToTime(duration);
      return `${hours}h${minutes}`;
    }
    return "";
  };

  const displayVoiceOverLabel = (event) => {
    const startStr = moment(event.startTime).format("HH:mm");
    if (event.recurring) {
      const endStr = moment(event.endTime).format("HH:mm");
      return `${startStr} - ${endStr}`;
    }
    return startStr;
  };

  return (
    <div
      style={{
        top: `${props?.style?.top}%`,
        height: `${props?.style?.height}%`,
        left: event?.type !== "voiceOver" ? `${event?.leftOffset}%` : "auto",
        right: event?.type === "voiceOver" ? `${event.leftOffset}%` : "auto",
        width: `${event?.width}%`,
      }}
      onClick={() => props.onClick(event)}
      className={`event-wrapper ${event.type}`}
    >
      {event.type !== "voiceOver" && <div className="line">{props.label}</div>}
      {event.type === "voiceOver" && (
        <div className="line">{displayVoiceOverLabel(event)}</div>
      )}
      {event.type === "voiceOver" && event?.name && (
        <div className="line">{event.name}</div>
      )}
      {/* {event?.playlist?.assets?.length && (
        <div className="line">{event?.playlist?.assets?.length} tracks</div>
      )}
      {event?.playlist?.duration && (
        <div className="line">{durationDisplay(event?.playlist?.duration)}</div>
      )} */}
    </div>
  );
};

const PlayButton = (props) => {
  if (props?.newVoiceOver?.id) {
    if (props.audio === props?.newVoiceOver?.id) {
      if (props.audioStatus === "playing") {
        return (
          <div
            onClick={() => {
              props?.audioRef?.audio?.current?.pause();
              props?.onPlayAudio(props?.newVoiceOver?.id, "pause");
            }}
            className="play-button"
          >
            <i className="icon-stop" />
          </div>
        );
      } else {
        return (
          <div
            onClick={() => {
              props?.audioRef?.audio?.current?.play();
              props?.onPlayAudio(props?.newVoiceOver?.id, "playing");
            }}
            className="play-button"
          >
            <i className="icon-play" />
          </div>
        );
      }
    } else {
      return (
        <div
          onClick={() => {
            props?.onPlayAudio(props.newVoiceOver?.id, "playing");
          }}
          className="play-button"
        >
          <i className="icon-play" />
        </div>
      );
    }
  }
};
